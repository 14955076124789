<template>
  <div class="inner-layout">

    <div class="layout-filter-box">
      <filters v-if="opt" :opt="opt" :obj="{ cateCode: this.cateCode, petCode: this.petCode, keyword: this.keyword, disease: this.disease }" @set="setFilters" />

      <mini-filter :opt="opt.cateCode" :target="cateCode" :targetTitle="'cateCode'" :title="'카테고리'" :inputType="'radio'" @set="setMiniFilter"/>

      <mini-filter :opt="opt.petCode" :target="petCode" :targetTitle="'petCode'" :title="'펫 구분'" :inputType="'checkbox'" @set="setMiniFilter"/>

      <mini-filter :opt="opt.keyword" :target="keyword" :targetTitle="'keyword'" :title="'관심 키워드'" :inputType="'checkbox'" @set="setMiniFilter"/>

      <mini-filter :opt="opt.disease" :target="disease" :targetTitle="'disease'" :title="'관심 질환'" :inputType="'checkbox'" @set="setMiniFilter"/>
    </div>

    <div class="sort-box">
      <div v-for="(item, index) in opt.sort" :key="`sort${index}`" :class="{'on' : item.value === sort}" @click="setSort(item.value)">{{ item.text }}</div>
    </div>

    <div class="item-container column-4" v-if="list && list.length > 0">
      <card v-for="item in list" :key="`lab-${item.boIdx}`" :item="item" cardType="default" @click="goDetail(item.boIdx)"></card>
    </div>
    <div v-else class="noDataMessage" style="border: 0">{{ $msg('nodata2') }}</div>

    <infinite-loading ref="infiniteLoading" spinner="spiral" :identifier="identifier" @infinite="infiniteHandler">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>
<script>
import {mapGetters} from "vuex"
import listMixin from "@/mixins/listMixin"
export default {
  name: "labList",
  mixins: [listMixin],
  data() {
    return {
      parentCategory: 20,
      cateCode: 20,
    }
  },
  computed: {
    ...mapGetters({
      getKeywordCode: 'common/getKeywordCode',
      getDiseaseCode: 'common/getDiseaseCode',
    }),
  },
  created() {
    this.setOptCateCode()
    setTimeout(() => {
      this.opt.keyword = this.getKeywordCode
      this.opt.disease = this.getDiseaseCode
    }, 300)
    if (localStorage.getItem('labFilter')) {
      const filter = JSON.parse(localStorage.getItem('labFilter'))
      this.cateCode = filter.cateCode
      this.petCode = filter.petCode.length > 0 ? filter.petCode.split(',') : []
      this.keyword = filter.keyword.length > 0 ? filter.keyword.split(',') : []
      this.disease = filter.disease.length > 0 ? filter.disease.split(',') : []
    }
  },
  methods: {
    async setOptCateCode() {
      const { result, data } = await this.$api.getCtgry({ cate: this.parentCategory });
      if (result === 'success' && data.length > 0) {
        this.opt.cateCode = data.reduce((list, data) => {
          data.text = data.categoryName
          data.value = data.cateCode
          list.push(data)
          return list
        }, [])
        this.opt.cateCode.unshift({ text: '전체', value: this.parentCategory },)
      }
    },
    setFilters(obj) {
      this.cateCode = obj.cateCode
      this.petCode = obj.petCode
      this.keyword = obj.keyword
      this.disease = obj.disease
      this.search()
      this.setLocalStorage()
    },
    setMiniFilter(target, value) {
      this[target] = value
      this.search()
      this.setLocalStorage()
    },
    goDetail(id) {
      this.setBeforeContent()
      this.$router.push(`/lab/${id}`)
    },
    setLocalStorage() {
      localStorage.setItem('labFilter', JSON.stringify({
        cateCode: this.cateCode,
        petCode: this.petCode.join(','),
        keyword: this.keyword.join(','),
        disease: this.disease.join(','),
      }))
    }
  }
}
</script>
